import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import { H1 } from '../components/Heading'

const StyledTime = styled.time`
  display: block;
  margin-bottom: 1.5em;
  text-align: center;
  color: #555;
`

const StyledBlogContent = styled.div`
  line-height: 2em;

  > * > a {
    color: blue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  > p > code {
    color: red;
    background: #eee;
    padding: 0.25em 0.5em;
    font-size: 0.9em;
    word-break: break-all;
  }

  > .gatsby-highlight > pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1em;
    font-size: 1em;
    line-height: 2;
    white-space: pre;
    overflow-x: auto;
    padding-top: 2em;
    padding-bottom: 2em;
  }
`

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "DD MMM YYYY")
        rawDate: date(formatString: "X")
        desc
      }
    }
  }
`

function PostPage({ data }) {
  const {
    markdownRemark: {
      html,
      frontmatter: { title, desc, rawDate, date }
    }
  } = data
  return (
    <Layout>
      <Helmet
        title={title}
        meta={[
          {
            name: 'description',
            content: desc
          }
        ]}
      />
      <article>
        <H1 centered>{title}</H1>
        <StyledTime dateTime={rawDate}>{date}</StyledTime>
        <StyledBlogContent dangerouslySetInnerHTML={{ __html: html }} />
      </article>
    </Layout>
  )
}

PostPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        date: PropTypes.string.isRequired,
        rawDate: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        desc: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
}

export default PostPage
